import { InteractionStatus } from '@azure/msal-browser';
import { useStore } from 'effector-react';
import { useEffect, useMemo } from 'react';
import { Loading } from '../../../../components/loading/loading.component';
import { Guard } from '../../../../features/features.types';
import { useAutenticacao } from '../../../../hooks/autenticacao/autenticacao.hook';
import { ConfigsManager } from '../../../../infra/configs/configs.manager';
import { IdExterno } from '../../../../models/usuario.model';
import { $autenticacao } from '../../../../stores/autenticacao/autenticacao.store';
import { autenticado as onUsuarioAutenticado, setIsLead } from '../../../../stores/usuario/usuario.store';
import { handleSearchParamsV2 } from '../../../../utils/handle-search-params-v2/handle-search-params-v2';
import { handleSearchParams } from '../../../../utils/handle-search-params/handle-search-params';

export const LoginGuard: Guard = ({ fallback, render }) => {
  const { isEnabled } = ConfigsManager.getInstance();
  const enabledHandleSearchParamsV2 = isEnabled('FF_HANDLE_SEARCH_PARAMS_V2');
  const { autenticado, pendente, token } = useStore($autenticacao);
  const { loginHint, idExterno, isLead, enturmacao, origem } = useMemo(
    () => (enabledHandleSearchParamsV2 ? handleSearchParamsV2() : handleSearchParams()),
    [enabledHandleSearchParamsV2],
  );
  const { inProgress, getContaAtiva, acquireToken, signIn } = useAutenticacao({
    loginHint,
    isLeadParam: isLead,
    idExterno,
    enturmacao,
    origem,
  });

  const loading = useMemo(() => <Loading ariaLabel="Carregando página" />, []);

  // Tratamento do login
  useEffect(() => {
    if (!autenticado && loginHint && InteractionStatus.None === inProgress) {
      const conta = getContaAtiva();

      if (conta) {
        acquireToken();
      } else {
        signIn();
      }

      setIsLead(isLead);
    }
  }, [acquireToken, autenticado, getContaAtiva, inProgress, isLead, loginHint, signIn]);

  if (pendente) {
    return loading;
  }

  if (autenticado && token && (isLead || idExterno.atila)) {
    onUsuarioAutenticado(isLead ? ({ email: loginHint } as unknown as IdExterno) : idExterno);

    return fallback;
  }

  return render;
};
