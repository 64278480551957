import { IdExterno } from '../../models/usuario.model';
import { removeCookie } from '../cookie/remove-cookie';
import { UpdateLocalStorageProps } from './handle-search-params.types';

const getStoredValues = () => {
  return {
    storedAtila: localStorage.getItem('idExterno.atila'),
    storedOrigem: localStorage.getItem('origem'),
    storedEnturmacao: localStorage.getItem('enturmacao'),
  };
};

const getCookie = (valor: string) => {
  const cookieValor = document.cookie.split('; ').find((cookie) => cookie.startsWith(`${valor}=`));
  return cookieValor ? JSON.parse(decodeURIComponent(cookieValor.split('=')[1])) : null;
};

const getQueryParams = (searchParams: URLSearchParams) => {
  const getCookies = getCookie('userInfo');
  return {
    loginHint: getCookies?.email || searchParams.get('login_hint') || localStorage.getItem('login_hint'),
    forceLogin: searchParams.get('force_login'),
    atilaQuery: searchParams.get('idExterno.atila'),
    origemQuery: searchParams.get('origem') ?? getCookies?.origem,
    enturmacaoQuery: searchParams.get('enturmacao'),
  };
};

const updateLocalStorage = (params: UpdateLocalStorageProps) => {
  const { atila, origem, enturmacao } = params;
  localStorage.setItem('idExterno.atila', atila ?? '');
  localStorage.setItem('enturmacao', enturmacao ?? '');
  localStorage.setItem('origem', origem ?? '');
};

const updateSessionStorage = (forceLogin: string | null) => {
  if (forceLogin) {
    sessionStorage.setItem('force_login', forceLogin);
  }
};

const updateLoginHint = (loginHint: string | null) => {
  if (loginHint) {
    localStorage.setItem('login_hint', loginHint);
  }
};

const updateCookie = (origem: string | null) => {
  const getCookies = getCookie('userInfo');

  if (!origem && getCookies) {
    removeCookie('userInfo');
  }
};

export const handleSearchParams = () => {
  const searchParams = new URLSearchParams(document.location.search);
  const { storedAtila, storedOrigem, storedEnturmacao } = getStoredValues();
  const { loginHint, forceLogin, atilaQuery, origemQuery, enturmacaoQuery } = getQueryParams(searchParams);

  let origem = storedOrigem;
  const isLoginURL = searchParams.has('login_hint');

  if (origemQuery) {
    origem = origemQuery ?? origem;
  }

  let atila = atilaQuery ?? storedAtila;

  if (isLoginURL && atilaQuery) {
    origem = null;
  }

  const isLead = origem?.toLowerCase() === 'portal';

  if (isLead) {
    atila = null;
  }

  const enturmacao = enturmacaoQuery ?? storedEnturmacao;

  updateLocalStorage({ atila, origem, enturmacao });
  updateSessionStorage(forceLogin);
  updateLoginHint(loginHint);
  updateCookie(origem);

  const idExterno: IdExterno = {
    atila: isLead ? null : atila,
  };

  return { loginHint, forceLogin, idExterno, isLead, enturmacao, origem };
};
