import RefreshCcw from '@lift/assets/dist/assets/globals/icons/refresh_ccw';
import { LftButton, LftTypography } from '@lift/design-system-react-web/dist/components';
import { useEffect } from 'react';
import analyticsService from '../../../services/analytics/analytics.service';
import S from '../screen-error.styles';

export const Retentativa: React.FC<{ erro: string; retentar: () => void }> = ({ erro, retentar }) => {
  useEffect(() => {
    analyticsService.envError({
      code: '404', // Código do erro
      description: erro, // Descrição ou mensagem do erro (Ex.: Erro ao carregar conteúdo em Home Experimente)
      isFatal: false, // Se o erro for fatal, setar como true
    });
  }, [erro]);

  return (
    <S.Container data-testid="screen-error" data-section="section_erro-carregar-conteudo">
      <LftTypography component="title" size="small">
        Tivemos problemas ao carregar <br /> o conteúdo da página
      </LftTypography>

      <LftTypography component="paragraph" size="small">
        Pedimos desculpa pelo imprevisto. <br />
        Por favor, tente carregar novamente.
      </LftTypography>

      <S.ButtonContainer>
        <LftButton
          ariaLabel="Tentar novamente"
          actionLevel="primary"
          dataElement="tentar-novamente"
          icon={RefreshCcw}
          size="lg"
          type="button"
          lftClick={retentar}
        >
          Tentar novamente
        </LftButton>
      </S.ButtonContainer>
    </S.Container>
  );
};
