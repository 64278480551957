import { ConsultarCodigosCursosComplementaresParams } from '../../services/consultar-codigos-cursos-complementares/consultar-codigos-cursos-complementares.types';

export type TipoCurso = 'GRAD' | 'POS' | 'TEC';
export type Modalidade = 'P' | 'S' | 'T' | 'L' | 'SF' | 'V';

export const obterTipoCurso = (tipoCurso: TipoCurso): string => {
  const tipoCursoMap: Record<TipoCurso, string> = {
    GRAD: 'GRADUAÇÃO',
    POS: 'ESPECIALIZAÇÃO',
    TEC: 'FORMAÇÃO PROFISSIONAL NÍVEL TÉCNICO',
  };

  return tipoCursoMap[tipoCurso];
};

export const obterModalidade = (modalidade: Modalidade): string => {
  const modalidadeMap: Record<Modalidade, string> = {
    P: 'PRESENCIAL',
    S: 'SEMIPRESENCIAL',
    T: 'TOTAL EAD',
    L: 'TELEPRESENCIAL',
    SF: 'FLEX ESPECIAL',
    V: 'AO VIVO',
  };

  return modalidadeMap[modalidade];
};

export const buildUrlParams = (values: Partial<ConsultarCodigosCursosComplementaresParams>) => {
  const params = new URLSearchParams();

  if (values.codigoCurso) {
    params.append('codigoCurso', values.codigoCurso);
  }

  if (values.modalidade && values.tipoCurso) {
    params.append('modalidade', obterModalidade(values.modalidade as Modalidade));
    params.append('tipoCurso', obterTipoCurso(values.tipoCurso as TipoCurso));
  }

  return params.toString();
};
