import { LftLink } from '@lift/design-system-react-web/dist/components';
import { useCallback, useMemo } from 'react';
import { useAutenticacao } from '../../hooks/autenticacao/autenticacao.hook';
import { ConfigsManager } from '../../infra/configs/configs.manager';
import { removeCookie } from '../../utils/cookie/remove-cookie';
import { handleSearchParamsV2 } from '../../utils/handle-search-params-v2/handle-search-params-v2';
import { handleSearchParams } from '../../utils/handle-search-params/handle-search-params';
import S from './logout.styles';

export const Logout = () => {
  const { isEnabled } = ConfigsManager.getInstance();
  const enabledHandleSearchParamsV2 = isEnabled('FF_HANDLE_SEARCH_PARAMS_V2');
  const { loginHint, idExterno, isLead, enturmacao, origem } = useMemo(
    () => (enabledHandleSearchParamsV2 ? handleSearchParamsV2() : handleSearchParams()),
    [enabledHandleSearchParamsV2],
  );

  const { signOut } = useAutenticacao({
    loginHint,
    isLeadParam: isLead,
    idExterno,
    enturmacao,
    origem,
  });

  const signOutHandle = useCallback(() => {
    signOut();
    localStorage.removeItem('login_hint');
    localStorage.removeItem('as');
    removeCookie('userInfo');
  }, [signOut]);

  return (
    <S.LogoutWrapper data-testid="signout">
      <LftLink label="Sair" lftClick={signOutHandle} tabIndex={0} />
    </S.LogoutWrapper>
  );
};
